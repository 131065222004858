import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import * as styles from './post-intro.module.scss'

const PostIntro = ({ post }) => {
  const catArray = post.categories?.map((cat) => cat.title)

  return (
    <div className={styles.postIntro}>
      <div className={styles.postIntroImageHolder}>
        <div className={styles.postIntroImage} style={{ backgroundImage: `url(${imageUrlFor(buildImageObj(post._rawMainImage)).width(1200).fit('fill').url()})` }} aria-label={post._rawMainImage.alt} />
      </div>

      <div className={styles.postIntroContent} data-animate-in>
        <h1>{post.title}</h1>

        {catArray?.length > 0 && <span className={styles.categories}>{catArray?.join(', ')}</span>}
      </div>
    </div>
  )
}

export default PostIntro
