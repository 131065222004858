// extracted by mini-css-extract-plugin
export var hide = "prev-next-module--hide--TKOGw";
export var allHide = "prev-next-module--all--hide--r1nKr";
export var smallHide = "prev-next-module--small--hide--5cJU0";
export var mediumHide = "prev-next-module--medium--hide---K+dn";
export var mediumDownHide = "prev-next-module--medium-down--hide--CkrGo";
export var mediumUpHide = "prev-next-module--medium-up--hide--boNdL";
export var largeHide = "prev-next-module--large--hide--S6MUA";
export var xlargeHide = "prev-next-module--xlarge--hide--siHa1";
export var xxlargeHide = "prev-next-module--xxlarge--hide--13Nje";
export var prevNext = "prev-next-module--prevNext--FhKsl";
export var prev = "prev-next-module--prev--exxqS";
export var next = "prev-next-module--next--aL2g-";