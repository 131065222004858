import React from 'react'
import PageTrans from '../page-trans/page-trans'
import * as styles from './prev-next.module.scss'

const PrevNext = ({ prev, next }) => {
  return (
    <div className={styles.prevNext}>
      <div className={styles.prev}>
        {
          prev &&
            <PageTrans fade to={`/${prev.slug.current}`}>
              <svg width='127' height='127' viewBox='0 0 127 127' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M7.75736 58.7574C5.41421 61.1005 5.41421 64.8995 7.75736 67.2426L45.9411 105.426C48.2843 107.77 52.0833 107.77 54.4264 105.426C56.7695 103.083 56.7695 99.2843 54.4264 96.9411L20.4853 63L54.4264 29.0589C56.7696 26.7157 56.7696 22.9167 54.4264 20.5736C52.0833 18.2304 48.2843 18.2304 45.9411 20.5736L7.75736 58.7574ZM114 57L12 57L12 69L114 69L114 57Z' fill='black'/>
              </svg>
              {prev.title}
            </PageTrans>
        }
      </div>

      <div className={styles.next}>
        {
          next &&
            <PageTrans fade to={`/${next.slug.current}`}>
              {next.title}
              <svg width='127' height='127' viewBox='0 0 127 127' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M118.243 67.2426C120.586 64.8995 120.586 61.1005 118.243 58.7574L80.0589 20.5736C77.7157 18.2304 73.9167 18.2304 71.5736 20.5736C69.2304 22.9167 69.2304 26.7157 71.5736 29.0589L105.515 63L71.5736 96.9411C69.2304 99.2843 69.2304 103.083 71.5736 105.426C73.9167 107.77 77.7157 107.77 80.0589 105.426L118.243 67.2426ZM12 69H114V57H12V69Z' fill='black'/>
              </svg>
            </PageTrans>
        }
      </div>
    </div>
  )
}

export default PrevNext
