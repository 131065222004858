
import { graphql } from 'gatsby'
import React from 'react'
import BlockContent from '../components/block-content/block-content'
import PostIntro from '../components/post-intro/post-intro'
import PrevNext from '../components/prev-next/prev-next'
import SEO from '../components/seo/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      keywords
      title
      description
    }

    post: sanityPost(id: {eq: $id}) {
      id
      title
      excerpt
      _rawMainImage
      _rawBody
      categories {
        title
      }
    }
  }
`

const PostTemplate = (props) => {
  const { data, errors } = props
  const { post, site } = data

  return (
    <Layout>
      <SEO title={post.title || site.title } description={post.excerpt} image={post._rawMainImage} />
      <div className='content-wrapper'>
        <PostIntro post={post} />
        <div className='post-content'>
          <BlockContent blocks={post._rawBody} inPage />
        </div>
        <PrevNext prev={props?.postContext?.previous} next={props?.postContext?.next} />
      </div>
    </Layout>
  )
}

export default PostTemplate
